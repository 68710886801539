// ShopForm.js

import React, { useState } from 'react';
import axios from 'axios';

const ShopForm = () => {
  const [name, setName] = useState('');
  const [weight, setWeight] = useState('');
  const [cost, setCost] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:5000/api/products', {
        name,
        weight,
        cost,
        imageUrl
      });
      alert('Product added successfully');
      // Optionally, you can reset the form fields here
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      <input type="text" placeholder="Weight" value={weight} onChange={(e) => setWeight(e.target.value)} />
      <input type="text" placeholder="Cost" value={cost} onChange={(e) => setCost(e.target.value)} />
      <input type="text" placeholder="Image URL" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
      <button type="submit">Add Product</button>
    </form>
  );
};

export default ShopForm;
